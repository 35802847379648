<template>
  <div class="wrap">
    <back-step
      :goList="[{
        url: '/',
        name: `${$t('home.personal.personal.zy')}`
      }]"
      :currentTxt="`${$t('home.home.xtgg')}`"
    />
    <div class="home_container">
      <div class="announcement_box">
        <div class="announcement_title">
          <p class="title_01">{{$t('home.home.ggbt')}}</p>
          <p class="title_02 tac">{{$t('home.home.sj')}}</p>
          <!--<p class="title_02 tac">{{$t('home.publicChildren.msgPage.cz')}}</p>-->
        </div>
        <ul class="announcement_ul"
            v-if="announcement.list.length"
            @click.stop="announcementDetail">
          <li class="an_single"
              v-for="(item,index) in announcement.list" :key="item.id">
            <div class="click_box" :data-key="item.id"></div>
            <p class="title_01 txt-ellipsis2">{{item.title}}</p>
            <p class="title_02 tac">{{item.updateDatetime}}</p>
          </li>
        </ul>
        <p class="ann_no tac" v-else>{{$t('home.home.zwgg')}}～</p>
      </div>
    </div>
    <el-pagination
      v-if="!isLoading"
      class="fr mb2rem"
      :page-sizes="[10, 20, 30, 50, 100]"
      :current-page="queryParams.pageNum"
      :page-size="queryParams.pageSize"
      @size-change="changeRouteQuery({
          pageSize:arguments[0],
          pageNum:1
        })"
      @current-change="changeRouteQuery({
          pageNum:arguments[0]
        })"
      background
      layout="total, sizes, prev, pager, next"
      :total="announcement.total">
    </el-pagination>
  </div>
</template>

<script>
  import {
    extend,
    get
  } from "lodash"
  import {
    mapGetters,
    mapActions
  } from 'vuex';
  import BackStep from '../../cases/cases-detail/childrenPublic/backStep';
  import {querySmsListPage} from 'common/api/system';
  import {toNumber} from "../../../common/js/Fn";

  export default {
    data() {
      return {
        announcement: {
          list: [],
          total: '',
        },
        announcementList: [],
        isLoading: false,
      };
    },
    computed: {
      ...mapGetters({}),

      queryParams(){
        let query = this.$route.query;
        return {
          status: '1',
          target: 'DOCTOR',
          type: 'SYS',
          readFlag: '0',
          pageNum: toNumber(query.pageNum,1),
          pageSize: toNumber(query.pageSize,10),
          language: localStorage.getItem('user_lang') || 'zh_CN'
        }
      },

    },

    methods: {
      get,
      ...mapActions({}),
      announcementDetail(ev) {
        const target = ev.target;
        const list = Array.from(target.classList);
        if(list.includes('click_box')) {
          const key = target.getAttribute('data-key');
          sessionStorage.setItem('announcementId', key);
          this.goPage('/home/msgDetail');
        }
      },
      goPage(url) {
        if(url) {
          this.$router.push({
            path:url,
            query:extend({},this.$route.query)
          });
        }
      },
      getList(){
        this.isLoading = true;
        querySmsListPage(this.queryParams).then((data) => {
          this.isLoading = false;
          this.announcement.list = data.list || [];
          this.announcement.total = data.total || 0;
          this.GET_IS_SHOW_NEWS(data.length > 0);
        }).catch((err) => {
          this.isLoading = false;
        })
      },
      changeRouteQuery(args){
        let route = this.$route;
        this.$router.replace({
          name: route.name,
          query: extend({},route.query,args)
        });
        this.getList();
      },
    },
    components: {
      BackStep,
    },

    created() {
      this.getList();
    }
  }
</script>

<style lang="scss" scoped>
  .wrap{
    width: 13.44rem;
    margin: 0 auto;
    padding-top: 30px;
    .home_container{
      width: 100%;
      margin: 0 auto;
      background-color: white;
      .con_head{
        margin-bottom: 0.2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .con_head_left{
          display: flex;
          align-items: center;
          position: relative;
          .h_left_h5{
            margin-right: 0.68rem;
          }
          .h5_strong{
            font-size: 0.23rem;
            margin: 0 0.04rem;
          }
          .h_left_select{
            border: none;
            outline: none;
            padding: 0.1rem;
          }
          .l_select_icon{
            position: absolute;
            right: -1.5%;
            bottom: 38%;
            display: inline-block;
            width: 0.1rem;
            height: 0.05rem;
            background-size: 100% 100%;
            background-image: url("/static/images/home/web_hp_arrow.png");
          }
        }
        .con_head_right{
          width: 1.48rem;
          height: 0.44rem;
          background: rgb(81,30,122);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 1rem;
          cursor: pointer;
          .add_icon{
            display: inline-block;
            font-size: 0.18rem;
            font-weight: bold;
            margin-right: 0.06rem;
          }
          .h_r_txt{
            padding-top: 0.02rem;
          }
        }
      }
      .cases_ul{
        margin-bottom: 0.3rem;
        display: flex;
        justify-content: space-between;
        .cases_single{
          width: 32%;
          height: 2.92rem;
          box-sizing: border-box;
          padding: 0.2rem 0.18rem 0.3rem;
          background-color: #fff;
          border-radius: 0.06rem;
          display: flex;
          flex-direction: column;
          .single_h5{
            display: flex;
            justify-content: space-between;
            padding-bottom: 0.1rem;
            .single_h5_l{
              font-size: 0.15rem;
            }
            .single_h5_r{
              color: #511E7A;
              font-size: 0.14rem;
              cursor: pointer;
            }
          }
          .c_single_li{
            display: flex;
            align-items: center;
            padding: 0.2rem 0;
            border-bottom: 0.01rem solid #EBEBEB;
            cursor: pointer;
            position: relative;
            &:last-child{
              border: none;
            }
            .c_single_li_pic{
              width: 0.38rem;
              height: 0.38rem;
              margin-right: 0.07rem;
              border-radius: 100%;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
            .c_single_li_r{
              flex: 1;
              .li_r_left_h5{
                font-size: 0.15rem;
                margin-bottom: 0.08rem;
              }
              .li_r_left_p{
                color: #999;
                font-size: 0.14rem;
                display: flex;
                align-items: center;
                justify-content: space-between;

              }
            }
          }
          .cases_no{
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #999999;
            font-size: 0.15rem;
            .cases_no_icon{
              width: 0.48rem;
              height: 0.54rem;
              margin-bottom: 0.13rem;
              background-size: 100% 100%;
            }
            .cases_no_icon01{
              background-image: url("/static/images/home/web_hp_disposal.png");
            }
            .cases_no_icon02{
              background-image: url("/static/images/home/web_hp_treatment.png");
            }
            .cases_no_icon03{
              background-image: url("/static/images/home/web_hp_completed.png");
            }
          }
        }
      }
      .announcement_box{
        margin-bottom: 0.2rem;
        color: $main_theme_color_333;
        .announcement_title{
          display: flex;
          align-items: center;
          height: 0.54rem;
          font-size: 0.16rem;
          border-bottom: 0.01rem solid #E5E5E5;
        }
        .announcement_ul{
          background-color: #fff;
          .an_single{
            display: flex;
            align-items: center;
            height: 0.72rem;
            font-size: 0.14rem;
            color: $main_theme_color_333;
            position: relative;
          }
          .an_single:hover{
            background: #F9F9F9;
          }
        }
        .ann_no{
          color: #666666;
          font-size: 0.16rem;
          height: 0.88rem;
          line-height: 1.1rem;
          padding-left: 0.78rem;
          background-color: #fff;
        }
        .title_01{
          width: 80%;
          line-height: 0.16rem;
          flex: 1;
          padding-left: 0.6rem;
        }
        .title_02{
          flex: 1;
          display: flex;
          flex-flow: row-reverse;
          padding-right: 0.6rem;
        }
      }
    }
  }
</style>